import { useState, type ReactNode } from "react";
import { useAtomValue } from "jotai";
import { PostHogProvider } from "posthog-js/react";

import { ChannelProvider } from "shared/channel";
import { ApiProvider } from "./ApiProvider";
import { ThemeProvider } from "@/components/ThemeProvider";
import { MyContext } from "@/contexts/AppContext";
import { isAuthenticatedVar } from "@/variables";

const postHogApiKey = import.meta.env.VITE_REACT_PUBLIC_POSTHOG_KEY;
const postHogApiHost = import.meta.env.VITE_REACT_PUBLIC_POSTHOG_HOST;
const postHogUiHost = import.meta.env.VITE_REACT_PUBLIC_POSTHOG_UI_HOST;

const postHogOptions = {
  api_host: postHogApiHost,
  ...(postHogUiHost ? { ui_host: postHogUiHost } : {}),
};

const channelUrl = import.meta.env.VITE_REACT_APP_CHANNEL_ENDPOINT;

export interface ProvidersProps {
  children: ReactNode;
}

export function Providers({ children }: ProvidersProps) {
  const isAuthenticated = useAtomValue(isAuthenticatedVar);

  const [demo, setDemo] = useState(false);

  return (
    <PostHogProvider apiKey={postHogApiKey} options={postHogOptions}>
      <ApiProvider>
        <ChannelProvider
          channelUrl={channelUrl}
          isAuthenticated={isAuthenticated}
        >
          <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
            <MyContext.Provider value={{ demo, setDemo }}>
              {children}
            </MyContext.Provider>
          </ThemeProvider>
        </ChannelProvider>
      </ApiProvider>
    </PostHogProvider>
  );
}
